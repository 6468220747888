<template>
  <b-card
    v-if="data"
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4
            title="'Tooltip text here'"
            class="card-title mb-50 mb-sm-0 chart-title"
          >
            {{ $t('Monthly Earnings') }}
          </h4>
          <feather-icon
            v-b-tooltip.hover.v-primary="'This chart illustrates the cumulative sum of delivery charge for all orders which has been invoiced on the respective month. It specifically displays data from the past 12 months.'"
            icon="HelpCircleIcon"
            size="15"
            class="cursor-pointer text-primary"
          />
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          height="230"
          :options="chartOptions"
          :series="data.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      revenue_report: {},
      chartOptions: {
        chart: {
          type: 'line',
          toolbar: { show: false },
          zoom: { enabled: true },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [5, 7, 5],
          curve: 'straight',
          dashArray: [0, 8, 5],
        },
        grid: {
          padding: {
            top: -20,
          },
        },
        title: {
          text: '',
          align: 'left',
        },
        legend: {
          tooltipHoverFormatter(val, opts) {
            return `${val} - ${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}`
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        responsive: [
          {
            breakpoint: 3000,
            options: {
              xaxis: {
                categories: this.data.categories.lables,
                labels: {
                  show: true,
                  rotate: -35,
                  rotateAlways: true,
                },
              },
            },
          },
        ],
        xaxis: {
          categories: this.data.categories.lables,
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: false,
            hideOverlappingLabels: false,
            showDuplicates: false,
            trim: false,
            maxHeight: 250,
            style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 900,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
        },
        yaxis: {
          labels: {
            formatter(value) {
              return value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            },
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
        },
        tooltip: {
          fixed: true,
          enabled: true,
          intersect: false,
          y: [
            {
              title: {
                formatter(val) {
                  return `${val} (Rs)`
                },
              },
            },
            {
              title: {
                formatter(val) {
                  return `${val} per session`
                },
              },
            },
            {
              title: {
                formatter(val) {
                  return val
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>
