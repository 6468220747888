<template>
  <b-card
    v-if="data"
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0 chart-title">
            {{ $t(title) }}
          </h4>
          <feather-icon
            v-if="$t(title) != 'Citywise Delivery Statistics'"
            v-b-tooltip.hover.v-primary="'This chart presents data from the past 12 months, providing an up-to-date snapshot of current trends and patterns.'"
            icon="HelpCircleIcon"
            size="15"
            class="cursor-pointer text-primary"
          />
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          type="bar"
          height="300"
          :options="revenueReport.chartOptions"
          :series="data.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      revenueReport: {
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            zoom: { enabled: false },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            borderColor: '#f1f1f1',
          },
          responsive: [
            {
              breakpoint: 5000,
              options: {
                xaxis: {
                  categories: this.data.categories.lables,
                  labels: {
                    show: true,
                    rotate: -65,
                  },
                },
              },
            },
            {
              breakpoint: 767,
              options: {
                xaxis: {
                  categories: this.data.categories.lables,
                  labels: {
                    show: true,
                    rotate: -75,
                    maxHeight: 210,
                  },
                },

              },
            },
          ],
          xaxis: {
            categories: this.data.categories.lables,
            labels: {
              show: true,
              rotate: 0,
              rotateAlways: false,
              hideOverlappingLabels: false,
              showDuplicates: false,
              trim: false,
              minHeight: undefined,
              maxHeight: 200,
              style: {
                colors: [],
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 900,
                cssClass: 'apexcharts-xaxis-label',
              },
            },
          },
          yaxis: {
            tickAmount: 3,
            labels: {
              formatter(val) {
                return val.toLocaleString()
              },
            },
          },
          tooltip: {
            fixed: true,
            enabled: true,
            intersect: false,
          },
        },
      },
    }
  },
}
</script>
