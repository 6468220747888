<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title class="chart-title">
          Delivered / Failed</b-card-title>
        <b-card-text class="font-small-2" />
      </div>

    </b-card-header>

    <!-- body -->
    <b-card-body class="d-flex justify-content-center align-items-center mb-3">
      <vue-apex-charts
        type="pie"
        :width="chartWidth"
        :options="chartOptions"
        :series="data.series"
      />
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
        },
        labels: ['Delivered', 'Failed'],
        colors: ['#5e97f7', '#ea5455'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
      chartWidth: 370,
    }
  },
}
</script>

<style>

@media (max-width: 767px) {
  .card-browser-states {
    max-width: 100%;
  }
}
</style>
