<template>
  <section id="dashboard-ecommerce">
    <!-- <b-card-code class="align-content-center">
      <div class="demo-spacing-0">
        <b-alert
          variant="primary"
          show
        >
          <div class="alert-body">
            <span>You have 8 days left to settle the payment for the previous subscription invoice.</span>
          </div>
        </b-alert>
      </div>
    </b-card-code> -->
    <b-row class="match-height">
      <b-col
        md="4"
        class="align-content-center"
      >
        <ecommerce-statistics :data="tempdata.statisticsItems" />
      </b-col>
      <b-col
        md="8"
        class="align-content-center"
      >
        <ecommerce-financial-statistics :data="tempdata.financialstatisticsItems" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        md="6"
      >
        <div v-if="!isMonthlyEarningsLoading">
          <ecommerce-monthly-earnings-chart :data="tempdata.monthlyEarnings" />
        </div>
      </b-col>
      <b-col
        md="6"
      >
        <div v-if="!isBarChartLoading">
          <ecommerce-revenue-report :data="tempdata.monthlyOrders" />
        </div>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        md="6"
      >
        <div
          v-if="!isToBeInvoicesLoading"
        >
          <ecommerce-transactions :data="tempdata.toBeInvoices" />
        </div>
      </b-col>

      <b-col
        md="6"
      >
        <div
          v-if="!isDeliveredFailedLoading"
        >
          <ecommerce-delivered-failed :data="tempdata.deliveredFailed" />
        </div>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- Revenue Report Card -->
      <b-col
        md="12"
      >
        <div v-if="!isMerchantOnBoarding">
          <ecommerce-client-onboarded
            title="Merchants Onboarded"
            :data="tempdata.clientOnBoarded"
          />
        </div>
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <!-- <b-row class="match-height">
      <b-col
        md="12"
      >
        <div v-if="!isGroupedBarChartLoading">
          <div class="kpi-dashboard-alert">
            <b-alert
              variant="warning"
              show
              class="d-flex align-items-center justify-content-center text-align-center small"
            >
              This is the beta version of the Delivery Success Rate (DSR) KPI Dashboard.
            </b-alert>
          </div>
          <GroupedBarChart
            :data="tempdata.chartData"
          />
        </div>
      </b-col>
    </b-row> -->

    <b-row class="match-height">
      <!-- Citywise Statistic card -->
      <b-col
        md="12"
      >
        <div v-if="!isCitywiseStatsLoading">
          <ecommerce-client-onboarded
            title="Citywise Delivery Statistics"
            :data="tempdata.cityWiseStats"
          />
        </div>
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="12">
        <div v-if="!isMStatusStatistics">
          <ecommerce-company-table :table-data="tempdata.companyTable.data" />
        </div>
      </b-col>
      <!--/ Company Table Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// import BCardCode from '@core/components/b-card-code'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import EcommerceStatistics from '@/components/Dashboard/EcommerceStatistics.vue'
import EcommerceTransactions from '@/components/Dashboard/EcommerceTransactions.vue'
import EcommerceFinancialStatistics from '@/components/Dashboard/EcommerceFinancialStatistics.vue'
import EcommerceRevenueReport from '@/components/Dashboard/EcommerceRevenueReport.vue'
import EcommerceMonthlyEarningsChart from '@/components/Dashboard/EcommerceMonthlyEarning.vue'
import EcommerceCompanyTable from '@/components/Dashboard/EcommerceCompanyTable.vue'
import EcommerceClientOnboarded from '@/components/Dashboard/EcommerceClientOnboarded.vue'
import EcommerceDeliveredFailed from '@/components/Dashboard/EcommerceDeliveredFailed.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
// import GroupedBarChart from '@/components/Dashboard/GroupedBarChart.vue'

const DashboardRepository = RepositoryFactory.get('dashboard')
// eslint-disable-next-line no-unused-vars
const BusinessSettingsRepository = RepositoryFactory.get('businessSetting')

export default {
  components: {
    EcommerceClientOnboarded,
    BRow,
    BCol,
    // BAlert,
    // BCardCode,
    EcommerceTransactions,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceFinancialStatistics,
    EcommerceMonthlyEarningsChart,
    EcommerceCompanyTable,
    EcommerceDeliveredFailed,
    // GroupedBarChart,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      mainDashboard: {
        orderStatsData: {},
        financialStatsData: {},
        monthlyOrdersData: {},
        monthlyEarningsData: {},
        toBeInvoicesData: {},
        deliveredFailedData: {},
        MerchantOnBoardingData: {},
        statusStatisticsData: {},
        cityWiseStatsData: {},
        groupedBarChartData: {},
      },
      businessProfileCurrency: null,
      data: {},
      isBarChartLoading: false,
      isMonthlyEarningsLoading: false,
      isToBeInvoicesLoading: false,
      isDeliveredFailedLoading: false,
      isMerchantOnBoarding: false,
      isMStatusStatistics: false,
      isCitywiseStatsLoading: false,
      isGroupedBarChartLoading: false,
      tempdata: {
        chartData: {
          series: [
            {
              name: 'Forecasted',
              data: [],
            },
            {
              name: 'Actual Success',
              data: [],
            },
            {
              name: 'Actual Fail',
              data: [],
            },
          ],
        },
        statisticsItems: [
          {
            icon: 'BoxIcon',
            customClass: '',
            color: 'light-warning',
            title: 0,
            subtitle: 'Orders Count',
          },
          {
            icon: 'DollarSignIcon',
            customClass: 'ml-3',
            color: 'light-success',
            title: 0,
            subtitle: 'Delivery Charge',
          },
        ],
        financialstatisticsItems: [
          {
            icon: 'ShoppingBagIcon',
            customClass: '',
            color: 'light-info',
            title: 0,
            subtitle: 'Invoice Value',
          },
          {
            icon: 'LoaderIcon',
            customClass: '',
            color: 'light-warning',
            title: 0,
            subtitle: 'Pending Invoice',
          },
          {
            icon: 'CheckCircleIcon',
            customClass: '',
            color: 'light-success',
            title: 0,
            subtitle: 'Paid Invoice Value',
          },
          {
            icon: 'UserCheckIcon',
            customClass: '',
            color: 'light-primary',
            title: 0,
            subtitle: 'Approved Invoice',
          },
        ],
        monthlyEarnings: {
          categories:
              { lables: [] },
          series: [
            {
              name: 'Delivery Charge',
              data: [],
            },
          ],
        },
        toBeInvoices: [
          {
            avatarVariant: 'light-warning',
            avatar: 'CodesandboxIcon',
            mode: 'Collected COD',
            types: this.businessProfileCurrency || (localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency')),
            deduction: false,
            payment: 0,
          },
          {
            avatarVariant: 'light-success',
            avatar: 'TruckIcon',
            mode: 'Delivery Charge',
            types: this.businessProfileCurrency || (localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency')),
            deduction: false,
            payment: 0,
          },
          {
            avatarVariant: 'light-warning',
            avatar: 'PercentIcon',
            mode: 'Commission',
            types: this.businessProfileCurrency || (localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency')),
            deduction: false,
            payment: 0,
          },
          {
            avatarVariant: 'light-info',
            avatar: 'DollarSignIcon',
            mode: 'Invoice Value',
            types: this.businessProfileCurrency || (localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency')),
            deduction: false,
            payment: 0,
          },
        ],
        monthlyOrders: {
          categories:
            { lables: [] },
          series: [
            { data: [] },
          ],
        },
        clientOnBoarded: {
          categories:
              { lables: [] },
          series: [
            {
              name: 'Merchants',
              data: [],
            },
          ],
        },
        companyTable: {
          data: [],
        },
        deliveredFailed: {
          series: [],
        },
        cityWiseStats: {
          categories:
              { lables: [] },
          series: [
            {
              name: 'Delivered orders',
              data: [],
            },
          ],
        },
      },
    }
  },
  created() {
    this.getDashboardDetails()
    // Listen for refreshDashboardData event emitted from AppBreadCrumb.vue
    this.$root.$on('refreshDashboardData', () => {
      this.getDashboardDetails()
    })
  },
  async beforeCreate() {
    await this.getBusinessCurrency()
  },
  methods: {
    currentDate() {
      const current = new Date()
      return `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
    },
    getDashboardDetails() {
      // Set the initial number of items
      this.readDataforOrderStats()
      this.readDataforFinancialStats()
      this.readDataforMonthlyOrders()
      this.readDataforMonthlyEarnings()
      this.readDataforToBeInvoices()
      this.readDataforDeliveredFailed()
      this.readDataforMerchantOnBoarding()
      // this.readDataForDSRKPI()
      this.readDataforStatusStatistics()
      this.readDataforCitywiseStats()
      this.getBusinessCurrency()
    },
    async readDataforOrderStats() {
      try {
        const mainDashboardDataString = localStorage.getItem('main-dashboard')
        const mainDashboardData = JSON.parse(mainDashboardDataString)

        if (mainDashboardDataString && mainDashboardData.orderStatsData) {
          this.mainDashboard = mainDashboardData
        } else {
          const { data } = (await DashboardRepository.getOrderStatsFinance()).data
          this.mainDashboard.orderStatsData = data
          const refreshDate = new Date()

          localStorage.setItem('dashboard-refresh-time', JSON.stringify(refreshDate))
          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem('main-dashboard', JSON.stringify(this.mainDashboard))
        }
        this.tempdata.statisticsItems[0].title = this.mainDashboard.orderStatsData.order_count
        this.tempdata.statisticsItems[1].title = this.mainDashboard.orderStatsData.total_delivery_charge
      } catch (e) {
        // Check if the error has a response and the status code is 401
        if (!e.response || e.response.status !== 401) {
          this.convertAndNotifyError(e)
        }
      }
    },
    async readDataForDSRKPI() {
      this.isGroupedBarChartLoading = true
      try {
        const mainDashboardDataString = localStorage.getItem('main-dashboard')
        const mainDashboardData = JSON.parse(mainDashboardDataString)

        if (mainDashboardDataString && mainDashboardData.groupedBarChartData) {
          this.mainDashboard = mainDashboardData
        } else {
          const { data } = await DashboardRepository.getDataForDSRKPI()

          // The response is now an array, so directly assign it
          this.mainDashboard.groupedBarChartData = data.data

          // Save the structured data to local storage
          localStorage.setItem('main-dashboard', JSON.stringify(this.mainDashboard))
        }

        // Set the chart data series based on the new structure
        this.tempdata.chartData.series[1].data = this.mainDashboard.groupedBarChartData.map(n => n.correct_values)
        this.tempdata.chartData.series[2].data = this.mainDashboard.groupedBarChartData.map(n => n.incorrect_values)

        // Calculate the sum of correct and incorrect values
        const correctValues = this.mainDashboard.groupedBarChartData.map(n => n.correct_values)
        const incorrectValues = this.mainDashboard.groupedBarChartData.map(n => n.incorrect_values)
        const sumValues = correctValues.map((value, index) => parseInt(value, 10) + parseInt(incorrectValues[index], 10))

        this.tempdata.chartData.series[0].data = sumValues
      } catch (e) {
        if (!e.response || e.response.status !== 401) {
          this.convertAndNotifyError(e)
        }
      }
      this.isGroupedBarChartLoading = false
    },
    async readDataforFinancialStats() {
      try {
        const mainDashboardDataString = localStorage.getItem('main-dashboard')
        const mainDashboardData = JSON.parse(mainDashboardDataString)
        if (mainDashboardDataString && mainDashboardData.financialStatsData) {
          this.mainDashboard = mainDashboardData
        } else {
          const { data } = (await DashboardRepository.getFinancialStatFinance()).data
          this.mainDashboard.financialStatsData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem('main-dashboard', JSON.stringify(this.mainDashboard))
        }
        this.tempdata.financialstatisticsItems[0].title = this.mainDashboard.financialStatsData.total_invoice_amount
        this.tempdata.financialstatisticsItems[1].title = this.mainDashboard.financialStatsData.status_amount.filter(item => item.status_key === 'f_key_1').length > 0 ? this.mainDashboard.financialStatsData.status_amount.filter(item => item.status_key === 'f_key_1')[0].amount : 0
        this.tempdata.financialstatisticsItems[2].title = this.mainDashboard.financialStatsData.status_amount.filter(item => item.status_key === 'f_key_2').length > 0 ? this.mainDashboard.financialStatsData.status_amount.filter(item => item.status_key === 'f_key_2')[0].amount : 0
        this.tempdata.financialstatisticsItems[3].title = this.mainDashboard.financialStatsData.status_amount.filter(item => item.status_key === 'f_key_3').length > 0 ? this.mainDashboard.financialStatsData.status_amount.filter(item => item.status_key === 'f_key_3')[0].amount : 0
      } catch (e) {
        // Check if the error has a response and the status code is 401
        if (!e.response || e.response.status !== 401) {
          this.convertAndNotifyError(e)
        }
      }
    },
    async readDataforMonthlyOrders() {
      this.isBarChartLoading = true
      try {
        const mainDashboardDataString = localStorage.getItem('main-dashboard')
        const mainDashboardData = JSON.parse(mainDashboardDataString)

        if (mainDashboardDataString && mainDashboardData.monthlyOrdersData) {
          this.mainDashboard = mainDashboardData
        } else {
          const { data } = (await DashboardRepository.getMonthlyOrdersFinance()).data
          this.mainDashboard.monthlyOrdersData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem('main-dashboard', JSON.stringify(this.mainDashboard))
        }
        this.tempdata.monthlyOrders.categories.lables = this.mainDashboard.monthlyOrdersData.map(n => (`${n.month} ${n.year}`)).reverse().slice(-12)
        this.tempdata.monthlyOrders.series[0].data = this.mainDashboard.monthlyOrdersData.map(n => n.data).reverse().slice(-12)
      } catch (e) {
        // Check if the error has a response and the status code is 401
        if (!e.response || e.response.status !== 401) {
          this.convertAndNotifyError(e)
        }
      }
      this.isBarChartLoading = false
    },
    async readDataforMonthlyEarnings() {
      this.isMonthlyEarningsLoading = true
      try {
        const mainDashboardDataString = localStorage.getItem('main-dashboard')
        const mainDashboardData = JSON.parse(mainDashboardDataString)

        if (mainDashboardDataString && mainDashboardData.monthlyEarningsData) {
          this.mainDashboard = mainDashboardData
        } else {
          const { data } = (await DashboardRepository.getMonthlyEarningsFinance()).data
          this.mainDashboard.monthlyEarningsData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem('main-dashboard', JSON.stringify(this.mainDashboard))
        }
        if (this.mainDashboard.monthlyEarningsData !== '' || this.mainDashboard.monthlyEarningsData !== undefined || this.mainDashboard.monthlyEarningsData !== '') {
          this.tempdata.monthlyEarnings.categories.lables = this.mainDashboard.monthlyEarningsData.map(n => (`${n.month} ${n.year}`)).reverse().slice(-12)
          this.tempdata.monthlyEarnings.series[0].data = this.mainDashboard.monthlyEarningsData.map(n => n.delivery_charge).reverse().slice(-12)
        } else {
          this.tempdata.monthlyEarnings.categories.lables = this.currentDate()
          this.tempdata.monthlyEarnings.series[0].data = [0]
        }
      } catch (e) {
        // Check if the error has a response and the status code is 401
        if (!e.response || e.response.status !== 401) {
          this.convertAndNotifyError(e)
        }
      }
      this.isMonthlyEarningsLoading = false
    },
    async readDataforToBeInvoices() {
      this.isToBeInvoicesLoading = true
      try {
        const mainDashboardDataString = localStorage.getItem('main-dashboard')
        const mainDashboardData = JSON.parse(mainDashboardDataString)

        if (mainDashboardDataString && mainDashboardData.toBeInvoicesData) {
          this.mainDashboard = mainDashboardData
        } else {
          const { data } = (await DashboardRepository.getToBEInvoicesFinance()).data
          this.mainDashboard.toBeInvoicesData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem('main-dashboard', JSON.stringify(this.mainDashboard))
        }
        if (this.mainDashboard.toBeInvoicesData[0].collected_cod === null) {
          this.tempdata.toBeInvoices[0].payment = 0
        } else {
          this.tempdata.toBeInvoices[0].payment = this.mainDashboard.toBeInvoicesData[0].collected_cod
        }
        if (this.mainDashboard.toBeInvoicesData[0].delivery_charge === null) {
          this.tempdata.toBeInvoices[1].payment = 0
        } else {
          this.tempdata.toBeInvoices[1].payment = this.mainDashboard.toBeInvoicesData[0].delivery_charge
        }
        if (this.mainDashboard.toBeInvoicesData[0].commision === null) {
          this.tempdata.toBeInvoices[2].payment = 0
        } else {
          this.tempdata.toBeInvoices[2].payment = this.mainDashboard.toBeInvoicesData[0].commision
        }
        if (this.mainDashboard.toBeInvoicesData[0].to_be_invoice_amount === null) {
          this.tempdata.toBeInvoices[3].payment = 0
        } else {
          this.tempdata.toBeInvoices[3].payment = this.mainDashboard.toBeInvoicesData[0].to_be_invoice_amount
        }
      } catch (e) {
        // Check if the error has a response and the status code is 401
        if (!e.response || e.response.status !== 401) {
          this.convertAndNotifyError(e)
        }
      }
      this.isToBeInvoicesLoading = false
    },
    async readDataforDeliveredFailed() {
      this.isDeliveredFailedLoading = true
      try {
        const mainDashboardDataString = localStorage.getItem('main-dashboard')
        const mainDashboardData = JSON.parse(mainDashboardDataString)

        if (mainDashboardDataString && mainDashboardData.deliveredFailedData) {
          this.mainDashboard = mainDashboardData
        } else {
          const { data } = (await DashboardRepository.getDeliveredFailedData()).data
          this.mainDashboard.deliveredFailedData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem('main-dashboard', JSON.stringify(this.mainDashboard))
        }
        this.tempdata.deliveredFailed.series[0] = this.mainDashboard.deliveredFailedData.delivered_count
        this.tempdata.deliveredFailed.series[1] = this.mainDashboard.deliveredFailedData.failed_count
      } catch (e) {
        // Check if the error has a response and the status code is 401
        if (!e.response || e.response.status !== 401) {
          this.convertAndNotifyError(e)
        }
      }
      this.isDeliveredFailedLoading = false
    },
    async readDataforMerchantOnBoarding() {
      this.isMerchantOnBoarding = true
      try {
        const mainDashboardDataString = localStorage.getItem('main-dashboard')
        const mainDashboardData = JSON.parse(mainDashboardDataString)

        if (mainDashboardDataString && mainDashboardData.MerchantOnBoardingData) {
          this.mainDashboard = mainDashboardData
        } else {
          const { data } = (await DashboardRepository.getMerchantOnBoardingFinance()).data
          this.mainDashboard.MerchantOnBoardingData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem('main-dashboard', JSON.stringify(this.mainDashboard))
        }
        this.tempdata.clientOnBoarded.categories.lables = this.mainDashboard.MerchantOnBoardingData.map(n => (`${n.month} ${n.year}`)).reverse().slice(-12)
        this.tempdata.clientOnBoarded.series[0].data = this.mainDashboard.MerchantOnBoardingData.map(n => n.data).reverse().slice(-12)
      } catch (e) {
        // Check if the error has a response and the status code is 401
        if (!e.response || e.response.status !== 401) {
          this.convertAndNotifyError(e)
        }
      }
      this.isMerchantOnBoarding = false
    },
    async readDataforStatusStatistics() {
      this.isMStatusStatistics = true
      try {
        const mainDashboardDataString = localStorage.getItem('main-dashboard')
        const mainDashboardData = JSON.parse(mainDashboardDataString)

        if (mainDashboardDataString && mainDashboardData.statusStatisticsData) {
          this.mainDashboard = mainDashboardData
        } else {
          const { data } = (await DashboardRepository.getStatusStatisticsFinance()).data
          this.mainDashboard.statusStatisticsData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem('main-dashboard', JSON.stringify(this.mainDashboard))
        }
        this.tempdata.companyTable.data = this.mainDashboard.statusStatisticsData
      } catch (e) {
        // Check if the error has a response and the status code is 401
        if (!e.response || e.response.status !== 401) {
          this.convertAndNotifyError(e)
        }
      }
      this.isMStatusStatistics = false
    },
    async readDataforCitywiseStats() {
      this.isCitywiseStatsLoading = true
      try {
        const mainDashboardDataString = localStorage.getItem('main-dashboard')
        const mainDashboardData = JSON.parse(mainDashboardDataString)

        if (mainDashboardDataString && mainDashboardData.cityWiseStatsData) {
          this.mainDashboard = mainDashboardData
        } else {
          const { data } = (await DashboardRepository.getCityWiseStats()).data
          this.mainDashboard.cityWiseStatsData = data

          // Convert the data to a JSON string and save it in local storage
          localStorage.setItem('main-dashboard', JSON.stringify(this.mainDashboard))
        }
        this.tempdata.cityWiseStats.categories.lables = this.mainDashboard.cityWiseStatsData.map(n => (`${n.city_name} (${n.warehouse_name})`))
        this.tempdata.cityWiseStats.series[0].data = this.mainDashboard.cityWiseStatsData.map(n => n.count)
      } catch (e) {
        // Check if the error has a response and the status code is 401
        if (!e.response || e.response.status !== 401) {
          this.convertAndNotifyError(e)
        }
      }
      this.isCitywiseStatsLoading = false
    },
    async getBusinessCurrency() {
      try {
        const { data: { company_currency } } = (await BusinessSettingsRepository.getbusinessSettingsResource()).data
        if (localStorage.getItem('businessCurrency') === true) {
          localStorage.removeItem('businessCurrency')
        }
        localStorage.setItem('businessCurrency', company_currency)
        // eslint-disable-next-line camelcase
        this.businessProfileCurrency = company_currency
      } catch (e) {
        // Check if the error has a response and the status code is 401
        if (!e.response || e.response.status !== 401) {
          this.convertAndNotifyError(e)
        }
        this.businessProfileCurrency = 'LKR'
      }
    },
  },
}
</script>

<style lang="scss">
@import '../@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '../@core/scss/vue/libs/chart-apex.scss';
.kpi-dashboard-alert .alert {
  line-height: 30px;
}
</style>
