<template>
  <b-card
    v-if="data"
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0 chart-title">
            {{ $t('Monthly Order') }}
          </h4>
          <feather-icon
            v-b-tooltip.hover.v-primary="'This chart illustrates the total of all orders added in the respective month, specifically displaying data from the past 12 months.'"
            icon="HelpCircleIcon"
            size="15"
            class="cursor-pointer text-primary"
          />
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="revenueReport.chartOptions"
          :series="data.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      revenue_report: {},
      revenueReport: {
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
            toolbar: { show: false },
          },
          stroke: {
            width: 2,
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: this.data.categories.lables,
            labels: {
              rotate: -45,
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: true,
            formatter(val) {
              return val.toLocaleString()
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
            background: {
              enabled: false,
            },
            position: 'top',
          },
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
              dataLabels: {
                position: 'top',
              },
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              formatter(value) {
                return value.toLocaleString()
              },
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
          },
          responsive: [
            {
              breakpoint: 767,
              options: {
                dataLabels: {
                  style: {
                    fontSize: '7px',
                  },
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>
